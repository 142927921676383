@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  height: 100%;
  margin: 0;
  box-sizing: border-box !important;
}
a {
  text-decoration: none;
}
body {
  font-family: "Poppins", sans-serif;
  /* position: relative !important; */
  min-height: 100%;
  margin: 0;
  box-sizing: inherit;
  --scrollbarBG: rgb(230, 227, 227);
  --thumbBG: #0060ad;
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

@font-face {
  font-family: whatsappFont;
  src: url(./asset/Helvetica\ برجسته\ 700.ttf);
}
@font-face {
  font-family: PoppinsBoldMarathi;
  src: url(./asset/Poppins-Bold.ttf);
}
.App {
  text-align: center;
}

.whatsApp-Heading h4 {
  color: #292828;
  font-weight: 600;
}
.whatsApp-Heading .whatsApp {
  font-family: whatsappFont;
  color: #25d366;
  font-weight: 800;
}
/* ----- Section Page CSS -----*/
.typeWriter {
  background: -webkit-linear-gradient(145deg, #0060ad, #0f8ef6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  font-family: "PoppinsBoldMarathi";
}
.typeWriter-2 {
  background: -webkit-linear-gradient(145deg, #0060ad, #0f8ef6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  font-family: "Yantramanav", sans-serif;
}

/* .home-section{
  height: 100%;
} */
.home-section .alert {
  border: 2px solid rgb(206, 204, 204);
  border-radius: 25px;
  width: max-content;
  padding: 10px 20px;
  color: gray;
  font-size: 14px;
}

.home-section .inputRow {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px 0px;
  padding: 25px 20px;
  border-radius: 15px;
  width: 75%;
}
.pageNumbers {
  list-style: none;
  display: flex;
}
.pageNumbers li {
  padding: 5px 10px;
  border: 1px solid black;
  cursor: pointer;
}
.pageNumbers li button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
.text-center {
  margin-top: 50px;
}
@media (max-width: 992px) {
  .home-section .inputRow {
    width: 90%;
  }
  .typeWriter {
    font-size: 30px;
  }
}
.home-section .inputRow input,
.home-section .inputRow select {
  border-radius: 10px;
}
.home-section .btn-join {
  /* box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%); */
  color: white;
  background: -webkit-linear-gradient(180deg, #0060ad, #0f8ef6);
  border-radius: 10px;
}
/* --- ERROR Showing Modal CSS ------*/

.modal .close {
  color: white;
  background: -webkit-linear-gradient(145deg, #0060ad, #0f8ef6);
}

/* ------ LOGIN PAGE CSS ------*/
.home-section,
.login-main {
  /* background: url('./asset/backGround.jpg') no-repeat center center fixed; */
  background-size: cover;
  /* height: 85vh; */
}

.login {
  height: 100%;
}
.login .card {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-width: 1px 0px 0px 1px;
  border-color: #dfeaf3;
}
.login input,
.login .input-group-text {
  border: 1px solid #a6d3f7;
}
.login .input-group-text {
  background: #329bf1;
  color: white;
}
.login .heading {
  font-weight: 800;
  color: #0060ad;
}
.btn-submit {
  border: 0px;
  border-radius: 8px;
  background: linear-gradient(145deg, #329bf1, #0060ad);
  color: white;
  padding: 6px 14px !important;
  transition: 0.3s;
}
.btn-submit:hover {
  transform: translateY(-2px);
  color: white !important;
}

.sendMsg {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url("./asset/backGround.jpg") center center no-repeat;
  background-size: cover;
}

.sendMsg .contact-card {
  border-radius: 10px;
  background: #ffffff !important;
  color: black;
  border: none;
  box-shadow: rgba(99, 99, 231, 0.3) 0px 5px 20px 5px;
}

.grpImg {
  width: 600px;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .grpImg {
    width: 100%;
    margin-bottom: 50px;
  }
  .contact-card {
    margin-top: 6%;
    margin-bottom: 60%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
footer {
  background-color: #1b186a;
  color: whitesmoke;
  padding: 10px 0px;
  /* position: absolute;
  padding: 10px 0px;
  left: 0;
  right: 0;
  bottom: 0; */
}
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 100px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 8px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #e1b42b;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
  left: 74px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
/* Transition
    ========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
